import React from "react"

import globalTheme from "../../styles/globalTheme"
import globalStyles from "../../styles/globalStyles"
import Header from "./header"
import Footer from "./footer"

import { ThemeProvider, withStyles } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"

const BaseStructure = ({ location, children, classes }) => {
  const rootPath = `${__PATH_PREFIX__}/`

  return (
    <ThemeProvider theme={globalTheme}>
      <CssBaseline />
      <div className={classes.layoutDiv}>
        <header>
          <Header rootPath={rootPath} currentPath={location.pathname} />
        </header>
        <main>{children}</main>
        <footer>
          <Footer rootPath={rootPath} currentPath={location.pathname} />
        </footer>
      </div>
    </ThemeProvider>
  )
}

export default withStyles(globalStyles)(BaseStructure)
