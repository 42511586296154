import React from "react"
import { useIntl } from "react-intl"
import { Link as InternalLink } from "gatsby"
import { useLocalization } from "gatsby-theme-i18n"

import Menu from "./menu"
import { localizedLink } from "../../utils/localizationService"

import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.primary.main,
    fontSize: "2em",
    fontStyle: "italic",
    fontVariant: "small-caps",
    fontFamily: '"Lucida Handwriting", cursive'
  },
  titleHome: {
    color: theme.palette.primary.main,
    fontSize: "2em",
    fontStyle: "italic",
    fontVariant: "small-caps",
    fontFamily: '"Lucida Handwriting", cursive'
  },
  typoContainer: {
    paddingBottom: "20px",
    paddingTop: "20px",
  },
}))

const Header = props => {
  const classes = useStyles(props)
  const { locale } = useLocalization()
  const intl = useIntl()
  const home = props.rootPath === props.currentPath

  return (
    <Container className="fullWidth">
      <Container className={classes.typoContainer + " standardWidth"}>
        <InternalLink to={localizedLink(`/`, locale)} className="noLinkStyle">
          <Typography
            className={home ? classes.titleHome : classes.title}
            variant="h1"
            component="h1"
          >
            {intl.formatMessage({ id: "title" })}
          </Typography>
        </InternalLink>
      </Container>
      <Menu rootPath={props.rootPath} currentPath={props.currentPath} />
    </Container>
  )
}

export default Header
