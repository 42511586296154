import { createMuiTheme } from "@material-ui/core/styles"

const globalTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#AE2829",
    },
    secondary: {
      main: "#263D42",
    },
    labelColors: {
      c1: "ae2829",
      c2: "263d42",
      c3: "8db38b",
      c4: "ff8c42",
      c5: "ffc53a",
      c6: "1282a2",
      c7: "48beff",
    },
    text: {
      white: "#FFFFFF",
    },
  },
  overrides: {
    MuiDivider: {
      root: {
        marginTop: "24px",
        marginBottom: "4px",
      },
    },
    MuiTimelineItem: {
      missingOppositeContent: {
        "&:before": {
          content: '""',
          flex: "0",
          padding: "0",
        },
      },
    },
  },
})

export default globalTheme
