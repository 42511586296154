import * as moment from "moment"

export const formatDateStd = (dateString, locale, config) => {
  const format = config.find(n => n.code === locale).dateFormat
  moment.locale(locale)
  return moment(dateString).format(format)
}

export const localizedLink = (internalURL, locale) => {
  //internalurl vorne abschneiden, wenn beginnend mit /en
  const url =
    internalURL.substring(0, 3) === "/en"
      ? internalURL.substring(3, internalURL.length)
      : internalURL
  return locale === "de" ? url : "/" + locale + url
}

export const localizationSwitch = (internalURL, locale) => {
  const newLocale = locale === "de" ? "en" : "de"
  const newLocaleName = locale === "de" ? "English Version" : "Deutsche Version"
  return { link: localizedLink(internalURL, newLocale), name: newLocaleName }
}
