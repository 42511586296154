import React from "react"
import { useIntl } from "react-intl"
import { Link as InternalLink } from "gatsby"
import { useLocalization } from "gatsby-theme-i18n"

import { localizedLink } from "../../utils/localizationService"

import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(theme => ({
  footerContainer: {
    marginTop: "24px",
    textAlign: "center",
    backgroundColor: theme.palette.secondary.main,
  },
  white: {
    color: theme.palette.text.white,
  },
}))

const Footer = props => {
  const classes = useStyles(props)
  const { locale } = useLocalization()
  const intl = useIntl()

  const footerLinks = [
    {
      name: intl.formatMessage({ id: "site.footer.termsConditions" }),
      path: "/terms",
    },
    {
      name: intl.formatMessage({ id: "site.footer.privacyPolicy" }),
      path: "/privacy",
    },
    {
      name: intl.formatMessage({ id: "site.footer.credits" }),
      path: "/credits",
    },
  ]

  return (
    <Container className={classes.footerContainer + " fullWidth"}>
      <Container className="standardWidth flexBetween spaceL flexWrap">
        <Typography variant="body2" className={classes.white}>
          © {new Date().getFullYear()} | V6.0
        </Typography>
        <div className="flexSpacer"></div>
        {footerLinks.map(link => (
          <InternalLink
            key={link.name}
            to={localizedLink(link.path, locale)}
            className={classes.white + " noLinkStyle"}
          >
            <Typography variant="body2">{link.name}</Typography>
          </InternalLink>
        ))}
        {/* language switch
        <div className="flexSpacer"></div>
        <InternalLink
          to={localizationSwitchLink}
          className={classes.white + " noLinkStyle"}
        >
          <Typography variant="body2">{localizationSwitchName}</Typography>
        </InternalLink> */}
      </Container>
    </Container>
  )
}

export default Footer
