const globalStyles = {
  "@global": {
    ".standardWidth": {
      maxWidth: "1000px",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    ".fullWidth": {
      maxWidth: "none",
      paddingLeft: 0,
      paddingRight: 0,
    },
    ".noHorizontalSpacing": {
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: 0,
      marginRight: 0,
    },
    ".marginTopL": {
      marginTop: "24px",
    },
    ".marginTopM": {
      marginTop: "16px",
    },
    ".marginTopS": {
      marginTop: "8px",
    },
    ".marginBottomM": {
      marginBottom: "16px",
    },
    ".marginBottomS": {
      marginBottom: "8px",
    },
    ".marginBottomXS": {
      marginBottom: "4px",
    },
    ".spaceS": {
      "& > *": {
        margin: "0 2px",
      },
    },
    ".spaceM": {
      "& > *": {
        margin: "0 4px",
      },
    },
    ".spaceL": {
      "& > *": {
        margin: "0 8px",
      },
    },
    ".verticalSpaceM": {
      "& > *": {
        margin: "4px 0",
      },
    },
    ".noLinkStyle": {
      textDecoration: "none",
    },
    ".verticalFlexStart": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    ".verticalFlexBetweenLeft": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "left",
    },
    ".flexStart": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    ".flexCenter": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    ".flexEnd": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    ".flexBetween": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    ".flexWrap": {
      display: "flex",
      flexWrap: "wrap",
    },
    ".flexSpacer": {
      flex: "1 0 auto",
      width: "unset",
    },
    ".unsetWidth": {
      width: "unset",
    },
  },
  layoutDiv: {
    minHeight: "100vh",
    margin: 0,
    display: "grid",
    gridTemplateRows: "auto 1fr auto",
  },
}

export default globalStyles
