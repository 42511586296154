import React from "react"
import { useIntl } from "react-intl"
import { Link as InternalLink, useStaticQuery, graphql } from "gatsby"
import { useLocalization } from "gatsby-theme-i18n"

import { localizedLink } from "../../utils/localizationService"

import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import YouTubeIcon from "@material-ui/icons/YouTube"
import AudiotrackOutlinedIcon from "@material-ui/icons/AudiotrackOutlined"
import InstagramIcon from "@material-ui/icons/Instagram"
import EmailIcon from "@material-ui/icons/Email"

const useStyles = makeStyles(theme => ({
  menuBackground: {
    backgroundColor: theme.palette.secondary.main,
  },
  white: {
    color: theme.palette.text.white,
  },
}))

const Menu = props => {
  const classes = useStyles(props)
  const { locale } = useLocalization()
  const intl = useIntl()

  const mainMenu = [
    // { name: "home", path: "/" },
    { name: intl.formatMessage({ id: "site.menu.trips" }), path: "/" },
    { name: intl.formatMessage({ id: "site.menu.map" }), path: "/map" },
    { name: intl.formatMessage({ id: "site.menu.about" }), path: "/about" },
  ]

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            contact {
              youtube
              tiktok
              instagram
              email
            }
          }
        }
      }
    `
  )

  return (
    <Container className={classes.menuBackground + " fullWidth"}>
      <Container className="standardWidth flexBetween flexWrap">
        {mainMenu.map(link => (
          <Button key={link.name}>
            <InternalLink
              to={localizedLink(link.path, locale)}
              className={classes.white + " noLinkStyle"}
            >
              <Typography variant="button">{link.name}</Typography>
            </InternalLink>
          </Button>
        ))}
        <div className="flexSpacer"></div>
        <Container className="noHorizontalSpacing unsetWidth">
          <IconButton
            target="_blank"
            rel="noopener noreferrer"
            href={site.siteMetadata.contact.youtube}
            aria-label="our youtube channel"
          >
            <YouTubeIcon className={classes.white} />
          </IconButton>
          <IconButton
            target="_blank"
            rel="noopener noreferrer"
            href={site.siteMetadata.contact.tiktok}
            aria-label="our tiktok channel"
          >
            <AudiotrackOutlinedIcon className={classes.white} />
          </IconButton>
          <IconButton
            target="_blank"
            rel="noopener noreferrer"
            href={site.siteMetadata.contact.instagram}
            aria-label="Nataschas Instagram"
          >
            <InstagramIcon className={classes.white} />
          </IconButton>
          <IconButton
            target="_blank"
            rel="noopener noreferrer"
            href={"mailto:" + site.siteMetadata.contact.email}
            aria-label="email us"
          >
            <EmailIcon className={classes.white} />
          </IconButton>
        </Container>
      </Container>
    </Container>
  )
}

export default Menu
